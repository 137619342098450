<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">Philip Johnston</p>
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7">
                <div class="workout-detail-image">
                  <img src="https://fakeimg.pl/1014x840/" alt="">
                  <div class="workout-detail-image-content">
                    <div class="container">
                      <div class="d-flex justify-content-start align-items-end">
                        <div class="d-lg-none">
                          <p class="workout-detail-subtitle">TREINO COM PT</p>
                          <p class="workout-detail-title">Philip Johnston</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-info">
                  <div class="d-flex container">
                    <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                      <div class="workout-info-item-icon d-none d-lg-block">
                        <calendar/>
                      </div>
                      <p class="workout-info-item-title">03 NOV</p>
                      <p class="workout-info-item-subtitle">Data</p>
                    </div>
                    <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                      <div class="workout-info-item-icon d-none d-lg-block">
                        <clock2/>
                      </div>
                      <p class="workout-info-item-title">11:00</p>
                      <p class="workout-info-item-subtitle">Hora Início</p>
                    </div>
                    <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                      <div class="workout-info-item-icon d-none d-lg-block">
                        <clock/>
                      </div>
                      <p class="workout-info-item-title">60 min.</p>
                      <p class="workout-info-item-subtitle">Duração</p>
                    </div>
                  </div>
                </div>
                <div class="workout-detail-text-wrapper">
                  <div class="container">
                    <p class="workout-detail-text-title">Descrição do Treino</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius tortor nibh, sit amet tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit molestie</p>
                      </div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <equipmentBlock/>
    <div class="workout-detail-btn-wrapper d-lg-none">
      <div class="container d-flex justify-content-center align-items-center">
        <button type="button" class="generic-btn red-gradient workout-detail-btn">Iniciar Treino</button>
      </div>
    </div>
  </div>
</template>

<script>
import equipmentBlock from "@/components/equipment/equipment-block.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import calendar from "@/components/svg/calendar.vue";
import clock from "@/components/svg/clock.vue";
import clock2 from "@/components/svg/clock2.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import playIcon from "@/components/svg/play.vue";

export default {
  name: "workout-pt-detail",
  metaInfo () {
    return {
      title: this.$root.translations.treinar_por,
    }
  },
  components : {
    equipmentBlock,
    chevronLeft,
    bookmark,
    shareIcon,
    calendar,
    clock,
    clock2,
    chevronDown,
    playIcon,
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
  },
}
</script>