<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
    <path fill="#FFF" d="M19.983 3.333c-9.2 0-16.65 7.467-16.65 16.667 0 9.2 7.45 16.667 16.65 16.667C29.2 36.667 36.667 29.2 36.667 20c0-9.2-7.467-16.667-16.684-16.667zm.017 30c-7.367 0-13.333-5.966-13.333-13.333S12.633 6.667 20 6.667 33.333 12.633 33.333 20 27.367 33.333 20 33.333zm.833-21.666h-2.5v10l8.75 5.25 1.25-2.05-7.5-4.45v-8.75z" transform="translate(-1483 -222) translate(1480 219)"/>
  </svg>
</template>

<script>
  export default {
    name: "clock2"
  }
</script>